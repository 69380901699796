const defaultState = {
  validity: {},
  required: {},
  formState: 'active',
};

const form = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_VALIDITY':
      return { ...state, validity: action.value };
    case 'SET_REQUIRED':
      return { ...state, required: { ...state.required, [action.key]: action.value } };
    case 'SET_FORM_STATE':
      return { ...state, formState: action.value };
    default:
      return state;
  }
};

export default form;
