import React from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from '../reducers/rootReducer';

const windowExists = typeof window === 'object';

export const store = createStore(
  rootReducer,
  windowExists && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default function wrapWithProvider({ element }) {
  return <Provider store={store}>{element}</Provider>;
}
