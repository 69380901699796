import { combineReducers } from 'redux';
import form from './form';
import user from './user';

const rootReducer = combineReducers({
  form,
  user,
});

export default rootReducer;
