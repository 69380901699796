const defaultState = {
  signedIn: 'unknown',
  displayName: null,
  isAuthorized: false,
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case 'SIGNIN':
      return {
        ...state,
        signedIn: true,
        displayName: action.displayName,
        isAuthorized: action.isAuthorized,
      };
    case 'SIGNOUT':
      return { ...defaultState, signedIn: false };
    default:
      return state;
  }
};

export default user;
