/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Polyfills for IE environments
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

// needed for builds
import "tailwindcss/base.css"
import "tailwindcss/components.css"
import "tailwindcss/utilities.css"

import wrapWithProvider from './src/utils/redux';

export const wrapRootElement = wrapWithProvider;
